
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiChevronDown, mdiChevronUp, mdiFilter } from '@mdi/js'
import { isEqual } from 'lodash-es'

import WorkforcePlanningTimeline from '@/views/planning/views/workforcePlanningTimeline/components/WorkforcePlanningTimeline.vue'
import PositionDetailsTable from '@/views/planning/views/positionDetails/components/PositionDetailsTable.vue'

import { useFilter } from '@/composables/useFilter'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { useGetPositions, useGetPositionsBasic } from '@/api/position'
import { useGetProfUnitsBasic } from '@/api/profUnit'
import { useGetProfilesBasic } from '@/api/profile'

import {
  ACTIVE_TENDERING,
  FILTER_FIELD_KEY,
  POSITIONSTATUS,
  POSITION_STATUS,
  SOURCING_TYPE,
} from '@/views/planning/views/workforcePlanning/types'
import { FilterDataTypes, FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'
import { Rights } from '@/api/types/right'
import { useGetPositionScenariosBasic } from '@/api/positionsScenario'
import { PositionOutput } from '@/api/types/position'
import { useGetOrgUnitsBasic } from '@/api/orgUnit'
import { PROFILE_TYPE } from '@/api/types/profile'
import { CONTRACTUAL_RELATIONSHIP } from '@/views/types'

export default defineComponent({
  name: 'PositionTimeline',
  components: {
    WorkforcePlanningTimeline,
    PositionTimelineFilterBar: () =>
      import('@/views/planning/views/workforcePlanningTimeline/components/PositionTimelineFilterBar.vue'),
    PositionDetailsTable,
  },
  setup: (_, { root }) => {
    const {
      exec: getPositions,
      data: positions,
      isLoading: isLoadingPositions,
      paginationResponse,
    } = useGetPositions()

    const {
      exec: getScenarioPositions,
      data: scenarioPositions,
      isLoading: isLoadingScenarioPositions,
    } = useGetPositions()

    const {
      exec: getPositionScenariosBasic,
      data: positionScenariosBasic,
      isLoading: isLoadingPositionScenariosBasic,
    } = useGetPositionScenariosBasic()

    const { exec: getProfUnitsBasic, data: profUnits, isLoading: isLoadingProfUnits } = useGetProfUnitsBasic()

    const { exec: getOrgUnitsBasic, data: orgUnits, isLoading: isLoadingOrgUnits } = useGetOrgUnitsBasic()

    const { exec: getProfilesBasic, data: profiles, isLoading: isLoadingProfiles } = useGetProfilesBasic()

    const {
      exec: getPositionsBasic,
      data: positionsBasic,
      isLoading: isLoadingPositionsBasic,
    } = useGetPositionsBasic()

    const selectedPositionScenario = computed<number | null>(() =>
      Number(root.$route.params.id ?? getFilterByKey(FILTER_FIELD_KEY.ScenarioId).value)
    )

    const isFilterDropdownOpen = ref(false)

    function onToggleFilterDropdown() {
      if (!isFilterDropdownOpen.value) {
        isFilterDropdownOpen.value = true

        if (
          !profUnits.value?.length ||
          !profiles.value?.length ||
          !orgUnits.value?.length ||
          !positionsBasic.value?.length ||
          !positionScenariosBasic.value?.length
        ) {
          getProfUnitsBasic()
          getOrgUnitsBasic()
          getProfilesBasic({ params: { type: PROFILE_TYPE.ASSIGNMENT } })
          getPositionsBasic()
          getPositionScenariosBasic()
        }
      } else {
        isFilterDropdownOpen.value = false
      }
    }

    const showAcquisitions = ref<boolean>(false)

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: FILTER_FIELD_KEY.PersonName,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.person'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.Ids,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.ids'),
        items: computed(() => positionsBasic.value ?? []),
        isLoading: computed(() => isLoadingPositionsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ProfessionalUnitIds,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.professionalUnit'),
        items: computed(() => profUnits.value ?? []),
        isLoading: computed(() => isLoadingProfUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ProfessionalUnitIdsWithChildren,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.professionalUnit'),
        items: computed(() => profUnits.value ?? []),
        isLoading: computed(() => isLoadingProfUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.OrganizationalUnitIds,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.organizationalUnit'),
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.OrganizationalUnitIdsWithChildren,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.organizationalUnit'),
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ProfileIds,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.profile'),
        items: computed(() => profiles.value ?? []),
        isLoading: computed(() => isLoadingProfiles.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.BudgetSourceOrgUnitIds,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.budgetSourceOrgUnit'),
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.StartTo,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.startTo'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.EndFrom,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.endFrom'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.ContractualRelationships,
        value: null,
        label: root.$t('contractData.contracts.filters.contractualRelationships'),
        items: computed(() => CONTRACTUAL_RELATIONSHIP),
        dataTyp: FilterDataTypes.ArrayString,
      },
      {
        key: FILTER_FIELD_KEY.SourcingTypes,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.sourcingTypes'),
        items: computed(() => SOURCING_TYPE ?? []),
        dataTyp: FilterDataTypes.ArrayString,
      },
      {
        key: FILTER_FIELD_KEY.ScopeFrom,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.scopeFrom'),
        items: [],
        dataTyp: FilterDataTypes.Float,
      },
      {
        key: FILTER_FIELD_KEY.ScopeTo,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.scopeTo'),
        items: [],
        dataTyp: FilterDataTypes.Float,
      },
      {
        key: FILTER_FIELD_KEY.PositionStatuses,
        value: POSITIONSTATUS.ACTIVE,
        label: root.$t('planning.workforcePlanning.filters.positionStatuses'),
        items: computed(() => POSITION_STATUS ?? []),
        dataTyp: FilterDataTypes.ArrayString,
      },
      {
        key: FILTER_FIELD_KEY.ActiveTendering,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.activeTendering'),
        items: computed(() => ACTIVE_TENDERING ?? []),
        dataTyp: FilterDataTypes.Boolean,
      },
      {
        key: FILTER_FIELD_KEY.ScenarioId,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.positionScenario'),
        items: computed(() => positionScenariosBasic.value ?? []),
        isLoading: computed(() => isLoadingPositionsBasic.value),
        dataTyp: FilterDataTypes.Integer,
      },
      {
        key: FILTER_FIELD_KEY.NotAssignedByDate,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.notAssignedByDate'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.Acquisition,
        value: showAcquisitions.value,
        label: root.$t('planning.workforcePlanning.filters.acquisitions'),
        items: [],
        dataTyp: FilterDataTypes.Boolean,
      },
      {
        key: FILTER_FIELD_KEY.Limited,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.limited'),
        items: [],
        dataTyp: FilterDataTypes.Boolean,
      },
      {
        key: FILTER_FIELD_KEY.NotApproved,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.notApproved'),
        items: [],
        dataTyp: FilterDataTypes.Boolean,
      },
    ])

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      FILTER_FIELDS.value as FilterField[],
      init
    )
    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )

    const totalPositions = computed(() => paginationResponse.value.totalElements)

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      await getPositions({ params: { ...filterFieldsObject, ...paginationParams.value } })

      if (selectedPositionScenario.value) {
        await getScenarioPositions({
          params: {
            scenarioOriginalPositionIds: timelinePositions.value?.map((position) => position.id).join(','),
            scenarioIsNull: false,
            scenarioIds: selectedPositionScenario.value,
            size: 9999,
          },
        })
      }
    }

    debouncedCb.value()

    const timelinePositions = computed(() => positions.value)

    const timelineScenarioPositions = computed(() =>
      selectedPositionScenario.value ? scenarioPositions.value : null
    )

    const isLoadingData = computed(() => isLoadingPositions.value || isLoadingScenarioPositions.value)

    const selectedPositionDetails = ref<PositionOutput>()

    const dataSizes = [10, 20, 50, 100, 200]

    function getFilterByKey(key: FILTER_FIELD_KEY): FilterField {
      return FILTER_FIELDS.value.find((field) => field.key === key) as FilterField
    }

    const activeTendering = computed(() => getFilterByKey(FILTER_FIELD_KEY.ActiveTendering).value)
    const isAcquisitionVisible = computed(() => getFilterByKey(FILTER_FIELD_KEY.Acquisition).value as boolean)

    watch(
      () => activeTendering.value,
      () => {
        if (activeTendering.value === true) {
          showAcquisitions.value = true
          getFilterByKey(FILTER_FIELD_KEY.Acquisition).value = true
        } else {
          showAcquisitions.value = false
          getFilterByKey(FILTER_FIELD_KEY.Acquisition).value = null
        }
      },
      { deep: true, immediate: true }
    )

    watch(
      () => isAcquisitionVisible.value,
      () => {
        if (isAcquisitionVisible.value) {
          showAcquisitions.value = true
        } else {
          showAcquisitions.value = false
          getFilterByKey(FILTER_FIELD_KEY.ActiveTendering).value = null
        }
      },
      { deep: true, immediate: true }
    )

    return reactive({
      icons: {
        mdiFilter,
        mdiChevronDown,
        mdiChevronUp,
      },
      constants: {
        Rights,

        dataSizes,

        FILTER_FIELDS,
      },
      state: {
        timelinePositions,
        selectedPositionDetails,

        timelineScenarioPositions,

        isLoadingData,

        positionScenariosBasic,
        isLoadingPositionScenariosBasic,

        isFilterDropdownOpen,

        showAcquisitions,

        vuetifyTableOptions,
        paginationParams,
        totalPositions,
      },
      functions: {
        hasSufficientRights,
        debouncedCb,
      },
      listeners: {
        onToggleFilterDropdown,
      },
    })
  },
})
