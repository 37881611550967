var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "my-5",
    attrs: {
      "fluid": _vm.$vuetify.breakpoint.lgAndDown && true
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('v-btn', {
    staticClass: "rounded-lg white",
    attrs: {
      "text": "",
      "elevation": "0",
      "color": "primary"
    },
    on: {
      "click": _vm.listeners.onToggleFilterDropdown
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiFilter) + " ")]), _vm._v(" " + _vm._s(_vm.$t('misc.filter')) + " "), _c('v-icon', {
    attrs: {
      "right": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.state.isFilterDropdownOpen ? _vm.icons.mdiChevronUp : _vm.icons.mdiChevronDown) + " ")])], 1) : _vm._e()], 1), _c('v-expand-transition', {
    attrs: {
      "mode": "in-out"
    }
  }, [_vm.state.isFilterDropdownOpen ? _c('PositionTimelineFilterBar', {
    staticClass: "my-3 d-flex align-center",
    attrs: {
      "fields": _vm.constants.FILTER_FIELDS
    }
  }) : _vm._e()], 1), _c('v-expand-transition', {
    attrs: {
      "mode": "in-out"
    }
  }, [_vm.state.selectedPositionDetails ? _c('PositionDetailsTable', {
    staticClass: "my-3",
    attrs: {
      "position": _vm.state.selectedPositionDetails
    }
  }) : _vm._e()], 1), _c('v-data-table', {
    staticClass: "my-3",
    attrs: {
      "items": _vm.state.timelinePositions,
      "options": _vm.state.vuetifyTableOptions,
      "server-items-length": _vm.state.totalPositions,
      "footer-props": {
        'items-per-page-options': _vm.constants.dataSizes
      }
    },
    on: {
      "update:options": [function ($event) {
        return _vm.$set(_vm.state, "vuetifyTableOptions", $event);
      }, function ($event) {
        return _vm.functions.debouncedCb();
      }]
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [!_vm.state.isLoadingData ? _c('WorkforcePlanningTimeline', {
          attrs: {
            "positions": _vm.state.timelinePositions,
            "scenario-positions": _vm.state.timelineScenarioPositions,
            "show-acquisition": _vm.state.showAcquisitions
          },
          on: {
            "select:position": function selectPosition($event) {
              return _vm.state.selectedPositionDetails = $event;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }